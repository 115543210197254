// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-blog-can-i-use-flexbox-js": () => import("/opt/build/repo/src/pages/blog-can-i-use-flexbox.js" /* webpackChunkName: "component---src-pages-blog-can-i-use-flexbox-js" */),
  "component---src-pages-blog-coming-out-js": () => import("/opt/build/repo/src/pages/blog-coming-out.js" /* webpackChunkName: "component---src-pages-blog-coming-out-js" */),
  "component---src-pages-blog-new-year-2018-js": () => import("/opt/build/repo/src/pages/blog-new-year-2018.js" /* webpackChunkName: "component---src-pages-blog-new-year-2018-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-compression-decompressed-js": () => import("/opt/build/repo/src/pages/compression-decompressed.js" /* webpackChunkName: "component---src-pages-compression-decompressed-js" */),
  "component---src-pages-how-does-procedural-generation-work-random-noise-js": () => import("/opt/build/repo/src/pages/how-does-procedural-generation-work-random-noise.js" /* webpackChunkName: "component---src-pages-how-does-procedural-generation-work-random-noise-js" */),
  "component---src-pages-noise-and-procedural-generation-js": () => import("/opt/build/repo/src/pages/noise-and-procedural-generation.js" /* webpackChunkName: "component---src-pages-noise-and-procedural-generation-js" */),
  "component---src-pages-support-me-js": () => import("/opt/build/repo/src/pages/support-me.js" /* webpackChunkName: "component---src-pages-support-me-js" */),
  "component---src-pages-what-is-a-blockchain-js": () => import("/opt/build/repo/src/pages/what-is-a-blockchain.js" /* webpackChunkName: "component---src-pages-what-is-a-blockchain-js" */),
  "component---src-pages-what-is-bitcoin-ethereum-js": () => import("/opt/build/repo/src/pages/what-is-bitcoin-ethereum.js" /* webpackChunkName: "component---src-pages-what-is-bitcoin-ethereum-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

